export default {
  name: "Mahjouba Bouchouf",
  title: "Full Stack Developer",
  email: "mahjoubabouchoufe@gmail.com",
  phone: "0920160559",
  location: "Tripoli, Libya",
  about: "Passionate full stack developer with 3 years of experience in building scalable web applications. Proficient in both front-end and back-end technologies, with a strong focus on creating efficient and user-friendly solutions.",
  skills: [
    "Html","Css","JavaScript", "Botstrap","Tailwind", "Vue.js",
    "PHP", "Laravel",
    "Python", "Django",
    "MongoDB","MySQL",
    "RESTful APIs", "Docker", "AWS", "Git",
    "Agile Methodologies",
  ],
  experience: [
    {
      title: "Full Stack Developer",
      company: "TechInnovate Solutions",
      date: "June 2021 - Present",
      description: "Develop and maintain scalable web applications using React, Node.js, and PostgreSQL. Implement RESTful APIs and integrate third-party services. Collaborate with cross-functional teams to deliver high-quality software solutions."
    },
    {
      title: "Junior Web Developer",
      company: "Digital Creations Agency",
      date: "August 2019 - May 2021",
      description: "Assisted in the development of responsive websites using HTML, CSS, and JavaScript. Worked on PHP-based CMS platforms and contributed to the maintenance of existing web applications."
    }
  ],
  projects: [

    {
      "title": "FluencyCheck",
      "description": "Created a language proficiency testing website using Vue.js and Tailwind CSS. The site evaluates users' English proficiency levels and is designed to be extended for other languages. Features include dynamic test content loading and responsive design.",
      "technologies": ["Vue.js", "Tailwind CSS", "JavaScript", "JSON"]
    },
    {
      title: "Multivendor E-Commerce Platform",
      description: "Developed a scalable multivendor e-commerce platform using Laravel, Vue.js, and Tailwind CSS. Implemented features such as real-time inventory management, secure payment gateway integration, and a responsive admin dashboard.",
      technologies: ["Laravel", "Vue.js", "Tailwind CSS", "MySQL","RestfulApis", "Pluto Api"]
    },
    {
      title: "Task Management System",
      description: "Built a comprehensive task management system with real-time updates using React, Node.js, and Socket.io. Integrated with Google Calendar API for seamless scheduling and implemented drag-and-drop functionality for enhanced user experience.",
      technologies: ["React", "Node.js", "Express", "MongoDB", "Socket.io", "Google Calendar API"]
    },
    {
      title: "AI-Powered Chatbot",
      description: "Developed an AI-powered chatbot using Python and natural language processing libraries. Integrated with a customer support platform to provide automated responses and improve response times.",
      technologies: ["Python", "Flask", "Natural Language Processing", "Machine Learning", "RESTful API"]
    }
  ],
  socialLinks: {
    linkedin: "https://www.linkedin.com/in/mahjouba-bouchouf/",
    github: "https://github.com/manomano500"
  }
};