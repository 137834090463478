<template>
  <footer class="bg-purple-600 text-white py-8">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap justify-between items-center">
        <div class="w-full md:w-1/3 text-center md:text-left mb-4 md:mb-0">
          <h3 class="text-2xl font-semibold">DevPortfolio</h3>
        </div>
        <div class="w-full md:w-1/3 text-center mb-4 md:mb-0">
          <ul class="flex justify-center space-x-4">
            <li><a href="#" class="hover:text-purple-200 transition duration-300">GitHub</a></li>
            <li><a href="#" class="hover:text-purple-200 transition duration-300">LinkedIn</a></li>
            <li><a href="#" class="hover:text-purple-200 transition duration-300">Twitter</a></li>
          </ul>
        </div>
        <div class="w-full md:w-1/3 text-center md:text-right">
          <p>&copy; 2023 DevPortfolio. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
};
</script>