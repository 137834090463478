<template>
  <nav class="bg-white shadow-lg">
    <div class="max-w-6xl mx-auto px-4">
      <div class="flex justify-between">
        <div class="flex space-x-7">
          <div>
            <router-link to="/" class="flex items-center py-4 px-2">
              <span class="font-semibold text-gray-500 text-lg">DevPortfolio</span>
            </router-link>
          </div>
        </div>
        <div class="hidden md:flex items-center space-x-1">
          <router-link to="/" class="py-4 px-2 text-purple-500 border-b-4 border-purple-500 font-semibold">Home</router-link>
          <router-link to="/about" class="py-4 px-2 text-gray-500 font-semibold hover:text-purple-500 transition duration-300">About</router-link>
          <router-link to="/projects" class="py-4 px-2 text-gray-500 font-semibold hover:text-purple-500 transition duration-300">Projects</router-link>
          <router-link to="/contact" class="py-4 px-2 text-gray-500 font-semibold hover:text-purple-500 transition duration-300">Contact</router-link>
        </div>
        <div class="md:hidden flex items-center">
          <button @click="toggleMobileMenu" class="outline-none mobile-menu-button">
            <svg class="w-6 h-6 text-gray-500 hover:text-purple-500"
                 x-show="!showMenu"
                 fill="none"
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 stroke-width="2"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="hidden mobile-menu">
      <ul class="">
        <li><router-link to="/" class="block text-sm px-2 py-4 text-white bg-purple-500 font-semibold">Home</router-link></li>
        <li><router-link to="/about" class="block text-sm px-2 py-4 hover:bg-purple-500 transition duration-300">About</router-link></li>
        <li><router-link to="/projects" class="block text-sm px-2 py-4 hover:bg-purple-500 transition duration-300">Projects</router-link></li>
        <li><router-link to="/contact" class="block text-sm px-2 py-4 hover:bg-purple-500 transition duration-300">Contact</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  methods: {
    toggleMobileMenu() {
      const menu = document.querySelector(".mobile-menu");
      menu.classList.toggle("hidden");
    }
  }
};
</script>