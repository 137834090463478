<template>
  <header class="bg-white shadow-md">
    <nav class="container mx-auto px-6 py-3">
      <div class="flex justify-between items-center">
        <router-link to="/" class="text-2xl font-bold text-gray-800">MB</router-link>
        <div class="hidden md:flex space-x-4">
          <router-link v-for="link in links" :key="link.to" :to="link.to" class="text-gray-700 hover:text-gray-900 transition duration-300">
            {{ link.text }}
          </router-link>
        </div>
        <button @click="toggleMobileMenu" class="md:hidden focus:outline-none">
          <svg class="h-6 w-6 fill-current text-gray-700" viewBox="0 0 24 24">
            <path v-if="!mobileMenuOpen" d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"/>
            <path v-else d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
      <div v-if="mobileMenuOpen" class="md:hidden mt-3 space-y-2">
        <router-link v-for="link in links" :key="link.to" :to="link.to" class="block text-gray-700 hover:text-gray-900 transition duration-300">
          {{ link.text }}
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      mobileMenuOpen: false,
      links: [

        { to: '/skills', text: 'Skills' },
        { to: '/experience', text: 'Experience' },
        { to: '/projects', text: 'Projects' },
        { to: '/about', text: 'About' },
        { to: '/contact', text: 'Contact' },
      ],
    };
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
  },
};
</script>