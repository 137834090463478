import { createStore } from 'vuex';
import portfolioData from '../data/portfolio-data.js';

export default createStore({
  state: {
    portfolioData: portfolioData,
  },
  getters: {
    getPortfolioData: (state) => state.portfolioData,
  },
  mutations: {},
  actions: {},
  modules: {},
});